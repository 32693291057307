<template>
  <div v-if="recommends" style="margin: 90px 0px 0px;">
    <div class="btns" style="padding: 0px 10px;">
      <button @click="setRecommended('anime-serials')" :class="{ active: recommendedType === 'anime-serials' }">Аниме сериалы</button>
      <button @click="setRecommended('anime')" :class="{ active: recommendedType === 'anime' }">Аниме фильмы</button>
      <a href="https://1xkino.ru"><button>Кино</button></a>
      <!-- <a href="https://1xkino.ru"><button>Аниме</button></a>
      <a href="https://1xkino.ru"><button>Многосерийные аниме</button></a>
      <a href="https://1xkino.ru"><button>Фильмы</button></a>
      <a href="https://1xkino.ru"><button>Сериалы</button></a> -->
    </div>
    <div style="margin-top: 20px;" class="slider-wrapper">
      <div :style="`width:${recommendsD.length * 180}px;`" class="slider">
        <nuxt-link
          v-for="item in recommendsD" :key="`recommends-slider-item-${item.kinopoisk_id}`"
          :to="`/film/${postUrl(item.kinopoisk_id, item.title_ru)}`"
          tag="div"
          class="item"
        >
          <div class="poster">
            <img
              :data-src="`https://kinopoiskapiunofficial.tech/images/posters/kp/${item.kinopoisk_id}.jpg`"
              src="/static_files/no-poster.png"
              :alt="item.title_ru"
              class="lazyload"
            >
          </div>
          <strong>{{ item.title_ru }}</strong>
        </nuxt-link>
        <div @click="getMoreRecommended(++recommendedPage)" class="item">
          <div class="poster">
            <img
              src="/static_files/icons/more.png"
              class="lazyload"
              style="object-fit:contain;opacity:0.75;"
            >
          </div>
          <strong v-if="!loading">Показать еще</strong>
          <strong v-else>Идет загрузка...</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    recommendedType() {
      const slider = document.querySelector('.slider-wrapper')
      if (slider) {
        slider.scrollTo(0,0)
      }
    },
  },
  data() {
    return {
      recommendsD: [],
      recommends: null,
      recommendedType: 'anime-serials',
      recommendedPage: 1,
      loading: false,
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(`/api/collaps-top?page=0`)
    this.recommends = data
    this.recommendsD = data['anime-serials'] ? data['anime-serials'] : []
  },
  methods: {
    async getMoreRecommended(page) {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.get(`/api/collaps-top?page=${page}&category=${this.recommendedType}`)
      this.loading = false
      if (data.error) {

      } else {
        const items = this.recommendsD.concat(data)
        this.recommendsD = items
      }
    },
    setRecommended(type = 'anime-serials') {
      this.recommendedType = type
      this.recommendsD = this.recommends[type]
    },
  },
}
</script>